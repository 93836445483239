import React from 'react';
import { useTranslation } from 'react-i18next';
import sadArticle from '../../static/sad-article.png';
import { Grid, Typography } from '@mui/material';

export default function ArticleNotFoundPage({ articleID, titleKey, messageKeys, idString, idTextString }) {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center" alignItems="center" className='mt-16'>
            <Grid item>
                <img
                    id="info-guy"
                    src={sadArticle}
                    alt="sad-article"
                    className="inline-block h-[235px] mr-[48px]"
                />
            </Grid>
            <Grid item>
                <Typography variant="body1" className="text-[28px] font-Bosch_sans_light leading-[36px] mb-[22px]">
                    {t(titleKey)}
                </Typography>
                <Typography variant="body1" className="text-[15px] font-Bosch_sans_light leading-[28px]">
                    {t(idTextString)} <span className='font-Bosch_sans_bold'>{t(idString)} {articleID}</span>
                </Typography>
                {messageKeys.map((key, index) => (
                    <Typography key={index} variant="body1" className="text-[15px] font-Bosch_sans_light leading-[28px]">
                        {t(key)}
                    </Typography>
                ))}
            </Grid>
        </Grid>
    );
};