import React, { useEffect, useState } from 'react';
import InfoPopper from '../InfoPopper';
import { useTranslation } from 'react-i18next';
import SiriButton from './SiriButton';
import VpnBotton from './VpnBotton';
import axiosInstance from '../../../utils/axiosInstance';

const MobileDetailsTab = (props) => {
  const { userInfo } = props;
  const [siriStatus, setSiriStatus] = useState();
  const [vpnStatus, setVpnStatus] = useState();
  const { t } = useTranslation();
  const infoModalsData = [
    t('ui_spear.deviceMobileDetails.do_not'),
    t('ui_spear.devideMobileDetails.when_enabled_vpn'),
    t('ui_spear.devideMobileDetails.when_enabled_siri') //"When enabled, this feature allows you to use Siri without needing to unlock your device with a passcode or Face ID."
  ];
  const settingDetailsInfo = t(
    'ui_spear.deviceMobileDetails.setting_details_mobile_new_vpn'
  );

  const _isMemberOfADGroup = async () => {
    const activeDirectoryUrl = `/api/v1/ad/user/attributes?user=${userInfo.userNTID}&attribute=mobile`;
    try {
      let response = await axiosInstance.get(activeDirectoryUrl);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log('error', error);
      return false;
    }
  };

  const _getSiriButtonStatus = async () => {
    let localSiri = localStorage.getItem('siriEnable');
    let groupResponse = await _isMemberOfADGroup();

    let emailGroupResponse = false;
    groupResponse.data[0].mobile.map((group) => {
      if (group === 'siriEnableGroup') {
        emailGroupResponse = true;
      }
    });

    if (
      localSiri &&
      localSiri.indexOf('Pending') !== -1 &&
      localSiri.length > 7
    ) {
      if (emailGroupResponse === true && localSiri.indexOf('Enable') !== -1) {
        localStorage.setItem('siriEnable', 'active');
        setSiriStatus('Disable');
      } else if (
        emailGroupResponse === false &&
        localSiri.indexOf('Disable') !== -1
      ) {
        setSiriStatus('Enable');
      } else setSiriStatus('Pending');
    } else {
      if (emailGroupResponse === true) {
        setSiriStatus('Disable');
      } else setSiriStatus('Enable');
    }
  };

  const _getVpnButtonStatus = async () => {
    let localVpn = localStorage.getItem('vpnEnable');
    let groupResponse = await _isMemberOfADGroup();

    let emailGroupResponse = false;
    groupResponse.data[0].mobile.map((group) => {
      if (group === 'vpnEnableGroup') {
        emailGroupResponse = true;
      }
    });

    if (localVpn && localVpn.indexOf('Pending') !== -1 && localVpn.length > 7) {
      if (emailGroupResponse === true && localVpn.indexOf('Enable') !== -1) {
        localStorage.setItem('vpnEnable', 'active');
        setVpnStatus('Disable');
      } else if (
        emailGroupResponse === false &&
        localVpn.indexOf('Disable') !== -1
      ) {
        setVpnStatus('Enable');
      } else setVpnStatus('Pending');
    } else {
      if (emailGroupResponse === true) {
        setVpnStatus('Disable');
      } else setVpnStatus('Enable');
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.userNTID) {
      _getSiriButtonStatus();
      _getVpnButtonStatus();
    }
  }, [userInfo]);

  return (
    <div className="my-8">
      <div className="w-full lg:w-[50%] text-md text-ITSP_blue my-2 text-center">
        <div style={{ display: 'inline-flex' }}>
          <div style={{ marginRight: '0.5rem', lineHeight: '2' }}>
            <InfoPopper popperContent={settingDetailsInfo} />
          </div>
          {t('ui_spear.deviceMobileDetails.settings_details')}:{' '}
        </div>
      </div>
      <div className="grid w-full my-[0] mobile-responsive-table">
        <table className="w-full">
          <tbody>
            <tr className="m-0 p-0">
              <td className="w-full lg:w-[50%] text-right font-Bosch_sans_light px-0 py-2">
                {t('ui_spear.deviceMobileDetails.siri')}:{' '}
              </td>
              <td className="w-full lg:w-[50%] text-left m-0 ps-2 ">
                <SiriButton
                  siriStatus={siriStatus}
                  setSiriStatus={setSiriStatus}
                  userNTID={userInfo?.userNTID}
                />
                <div className="p-2" style={{ display: 'inline-block' }}>
                  <InfoPopper popperContent={infoModalsData[2]} />
                </div>
              </td>
            </tr>
            <tr className="m-0 p-0">
              <td className="w-full lg:w-[50%] text-right font-Bosch_sans_light px-0 py-2">
                {t('ui_spear.deviceMobileDetails.vpn')}:
              </td>
              <td className="w-full lg:w-[50%] text-left m-0 ps-2">
                <VpnBotton
                  vpnStatus={vpnStatus}
                  setVpnStatus={setVpnStatus}
                  userNTID={userInfo?.userNTID}
                />
                <div className="p-2" style={{ display: 'inline-block' }}>
                  <InfoPopper popperContent={infoModalsData[1]} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MobileDetailsTab;
