import axiosInstance from '../utils/axiosInstance';

// TODO: implement validations to the parameters or others possible errors

// SMT Endpoint to get tickets

export const fetchSmtTicketsForMyself = async (NTID) => {
  const url = `${process.env.REACT_APP_BE_URL}/api/v1/smt/tickets/details`;
  try {
    const dataToSend = {
      corporate_id: NTID,
      start_record: 0,
      max_limit: 500
    }; // Your data to be sent in the POST request
    const response = await axiosInstance.post(url, dataToSend)
    //console.log('TF SMT myself: ', {response});
    if (response.status === 200) {
      if (response.data.data && response.data.data.length === 0) {
        // User does not have SMT
        return response.data.data; //this should be an empty array.
      } else {
        // return smt tickets for the user
        return response.data;
      }
    } else {
      // TODO: error in smt call implement alert here!
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch Smt Tickets For Myself:', error);
    return [];
  }
};

export const fetchSmtTicketsForOthers = async (NTID) => {
  const url = `/api/v1/smt/tickets/details`;
  try {
    const dataToSend = {
      direct_contact_corporate_id: NTID,
      start_record: 0,
      max_limit: 200
    }; // Your data to be sent in the POST request

    const response = await axiosInstance.post(url, dataToSend);
    //console.log('TF smt others: ', {response});
    if (response.status === 200) {
      if (response.data.data && response.data.data.length === 0) {
        // User does not have SMT
        return response.data.data; //this should be an empty array.
      } else {
        // return smts for the user
        return response.data;
      }
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch Smt Tickets ForOthers:', error);
    return [];
  }
};

// SN Endpoint to get tickets
export const fetchSNTicketsForMyself = async (NTID) => {
  const url = `/api/v1/snow/tickets/mySelf/${NTID}`;
  const ctpFilter = (tickets) => {
    //SNOW-1912: Filter elements with the cat_item_name "Critical ticket via RedButton page - TOM OPS"
    return tickets.filter(
      (ticket) =>
        ticket.items[0].cat_item_name !==
        'Critical ticket via RedButton page - TOM OPS'
    );
  };

  try {
    // const response = await axios.get(url);
    const response = await axiosInstance.get(url);
    //console.log('TF sn myself: ', {response});
    if (response.status === 200) {
      if (response.data.isSuccessful) {
        const myTickets = ctpFilter(response.data.data[0].response);
        return myTickets;
      } else {
        console.log('Error with SN call, message: ', response.data.message);
        return [];
      }
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch SN Tickets For Myself:', error);
    return [];
  }
};

export const fetchSNTicketsForOthers = async (NTID) => {
  const url = `/api/v1/snow/tickets/forOthers/${NTID}`;
  try {
    const response = await axiosInstance.get(url);
    //console.log('TF sn others: ', {response});
    if (response.status === 200) {
      if (response.data.isSuccessful) {
        return response.data.data[0].response;
      } else {
        console.log('Error with SN call, message: ', response.data.message);
        return [];
      }
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch SN Tickets For Others:', error);
    return [];
  }
};

export const getServiceOfferingID = async (service) => {
  const url = `/api/v1/snow/feedback/valuemation/${service}`
  try {
    const response = await axiosInstance.get(url);
    if (response.status === 200) {
      return response.data
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch Offerinf ID:', error);
    return [];
  }
}

// CPSC Endpoint to get tickets

export const fetchCPSCOpenTicketsForMyself = async (NTID, codeLanguage) => {
  const url = `/api/v1/cpsc/tickets/mySelf/open/${NTID}?lang=${codeLanguage}`;
  try {
    const response = await axiosInstance.get(url);
    //console.log('TF cpsc open myself: ', {response});
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch CPSC Open Tickets For Myself:', error);
    return [];
  }
};

export const fetchCPSCClosedTicketsForMyself = async (NTID, codeLanguage) => {
  const url = `/api/v1/cpsc/tickets/mySelf/closed/${NTID}?lang=${codeLanguage}`;
  try {
    const response = await axiosInstance.get(url);
    //console.log('TF cspc close myself: ', {response});
    if (response.status === 200) {
      if (response.data.isSuccessful) {
        return response.data.data;
      } else {
        console.log('Error with CPSC call, message: ', response.data.message);
        return [];
      }
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch CPSC Closed Tickets For Myself:', error);
    return [];
  }
};

export const fetchCPSCOpenTicketsForOthers = async (NTID, codeLanguage) => {
  const url = `/api/v1/cpsc/tickets/forOthers/open/${NTID}?lang=${codeLanguage}`;
  try {
    const response = await axiosInstance.get(url);
    //console.log('TF cpsc open others: ', {response});
    if (response.status === 200) {
      if (response.data.isSuccessful) {
        return response.data.data;
      } else {
        console.log('Error with CPSC call, message: ', response.data.message);
        return [];
      }
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch CPSC Open Tickets ForOthers:', error);
    return [];
  }
};

export const fetchCPSCClosedTicketsForOthers = async (NTID, codeLanguage) => {
  const url = `/api/v1/cpsc/tickets/forOthers/closed/${NTID}?lang=${codeLanguage}`;
  try {
    const response = await axiosInstance.get(url);
    //console.log('TF cpsc closed others: ', {response});
    if (response.status === 200) {
      if (response.data.isSuccessful) {
        return response.data.data;
      } else {
        console.log('Error with CPSC call, message: ', response.data.message);
        return [];
      }
    } else {
      console.log('Axios error call with status:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetch CPSC Closed Tickets For Others:', error);
    return [];
  }
};

// Search SMT key user Endpoints

export const fetchSmtGetTicketDetailsById = async (
  incidentID,
  limit = null
) => {
  const url = `/api/v1/smt/tickets/details/id`;
  let smtTicket = {};

  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Connection: 'keep-alive',
    Accept: '*/*'
  };

  try {
    const dataPayload = {
      incident_number: incidentID,
      start_record: 0,
      max_limit: limit ? limit : 5
    };

    const response = await axiosInstance.post(url, dataPayload, headers);

    if (response.status === 200) {
      if (response.data) {
        smtTicket =
          response.data['soapenv:Envelope']['soapenv:Body'][0]
            .HelpDesk_Query_ServiceResponse &&
          response.data['soapenv:Envelope']['soapenv:Body'][0]
            .HelpDesk_Query_ServiceResponse;
        return smtTicket;
      }
    } else return smtTicket;
  } catch (error) {
    console.error('Error:', error);
  }
};

//Get Sap Key User
export const getSapKeyUser = async (NTID) => {
  const url = `/api/v1/smt/sapkeyuser/status/${NTID}`;
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
