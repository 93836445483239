import { Link } from 'react-router-dom';
import Profile from './Profile';
import { getShowPilot } from '../../utils/properties/utils';

const SNOW_SP_URL = process.env.REACT_APP_SNOW_SP_URL;

const TopNavbar = ({
  location,
  userInfo,
  styles,
  mainLinks,
  portalLanguages,
  handleLanguageChange,
  isSupportEntitle
}) => {
  const setSupportEntitle = (userInfo) => {
    isSupportEntitle = getShowPilot(userInfo);
  };

  if (userInfo !== null) {
    setSupportEntitle(userInfo);
  }

  const renderLinks = (links) => { 
    return links.map((link, key) => {
      if(!isSupportEntitle && link.path.includes('support')) {
        return;  
      }
      
      return (
        <li
          key={link.title}
          className={`px-4`}
          style={{
            borderRight: 
              isSupportEntitle ? 
                link.path.includes('support') ? 
                '.1rem solid #aab3b5'
                : ''
              : link.path == `${SNOW_SP_URL}` ? 
                '.1rem solid #aab3b5'
                : ''
          }}
        >
          <Link
            to={`${link.path}`}
            className="font-normal text-[#343d47] no-underline hover:text-[#428bca] text-md "
          >
            {link.title}
          </Link>
        </li>
      );
    })
        
  };

  return (
    <div className={styles}>
      <nav>
        <ul className="flex flex-row items-center">
          {renderLinks(mainLinks)}
          {userInfo && (
            <Profile
              className=""
              userInfo={userInfo}
              portalLanguages={portalLanguages}
              handleLanguageChange={handleLanguageChange}
            />
          )}
        </ul>
      </nav>
    </div>
  );
};

export default TopNavbar;
