import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import sadArticle from '../../static/sad-article.png';
import { ArrowCircleRight } from '@mui/icons-material';

export default function TicketNotFoundPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/tickets');
    };

    return (
        <div className="flex flex-col items-center mt-20 xl:mt-28">
            <div>
                <img
                    id="sad-article"
                    src={sadArticle}
                    className="max-h-[300px]"
                    alt="article-guy"
                />
            </div>
            <Typography className="font-Bosch_sans text-lg mb-4 text-center">
                {t('ui_spear.ticketDetails.TicketJourney_Ticket_Not_Found')}
            </Typography>
            <button
                className='mt-2 h-12 px-3 font-Bosch_sans hover:bg-change_cust_btn/80 py-px bg-change_cust_btn text-white border border-change_cust_btn'
                onClick={handleRedirect}
            >
                <ArrowCircleRight className="size-8 cursor-pointer mr-3 text-white" />
                {t('ui_spear.ticketDetails.Back_to_Tickets')}
            </button>
        </div>
    );
}