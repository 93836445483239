import React, { useState, useEffect, useRef } from 'react';
import { Grid, Popper, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ShareArticle(props) {
  const { t } = useTranslation();
  let [anchorEl, setAnchorEl] = useState(null);
  let [articleURL, setArticleURL] = useState(
    `${process.env.REACT_APP_UI_URL}/support?article=${props.articleID}`
  );
  const sharePopUpRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (!sharePopUpRef.current) {
      return;
    }
    if (!sharePopUpRef.current.contains(e.target)) {
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let copyToClipBoard = () => {
    navigator.clipboard.writeText(articleURL);
    props.toggleToastState(
      t('ui_spear.support.search_article_copied_clipboard')
    );
  };

  return (
    <div>
      <Button
        className="text-[#08c] mt-[-9px] mb[10px] ml[10px] mr-0 hover:decoration-[#005580] hover:text-[#005580] focus:outline-none focus:underline focus:text-[#005580] active:underline active:text-[#005580] p-0 normal-case"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <div
          className="flex items-center  hover:decoration-[#005580] hover:text-[#005580] focus:outline-none focus:underline focus:text-[#005580] active:underline active:text-[#005580]"
          style={{ fontFamily: 'Bosch Sans', fontSize: '14px' }}
        >
          <div className="result-icon">
            <div className=""> &#xe242; </div>
          </div>
          <div className="result-icon hover:underline">
            <div style={{ fontFamily: 'Bosch Sans', fontSize: '14px' }}>
              {`${t('ui_spear.support.search_share_this_article')} ${props.articleType
                }`}{' '}
            </div>
          </div>
        </div>
      </Button>
      <Popper
        ref={sharePopUpRef}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="right"
        className={
          props.popUpActive
            ? 'share-article-poppover bg-white z-1600 shadow-2xl'
            : 'share-article-poppover bg-white z-5 shadow-2xl'
        }
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <Grid style={{ maxWidth: '450px' }}>
          {/* Title and close button */}
          <Grid container xs={12} sx={{ minWidth: '100%' }} direction="row">
            <Grid item xs={11}>
              <Typography
                style={{
                  fontFamily: 'BoschSans-Bold',
                  display: 'inline-block',
                  padding: '10px'
                }}
              >{`${t('ui_spear.support.search_share_this_article')} ${props.articleType
                }`}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={() => handleClose()}
                style={{
                  display: 'inline-block',
                  textTransform: 'none',
                  float: 'right',
                  paddingRight: 0
                }}
              >
                <div
                  className="result-icon"
                  style={{
                    fontSize: '18px',
                    color: '#000',
                    marginLeft: '15px',
                    paddingTop: '4px'
                  }}
                >
                  &#xe0f0;
                </div>
              </Button>
            </Grid>
          </Grid>
          {/* Popover description / information */}
          <Grid>
            <Typography
              style={{
                fontFamily: 'Bosch Sans',
                fontSize: '14px',
                padding: '10px'
              }}
            >
              {t('ui_spear.search.Share.Anyone_with_this_link')}
            </Typography>
          </Grid>
          {/* Link box and copy button */}
          <Grid
            container
            xs={12}
            sx={{ minWidth: '100%', padding: '10px' }}
            direction="row"
          >
            <Grid
              item
              xs={9}
              style={{
                border: '1px solid #7FC6E7',
                paddingLeft: '6px',
                paddingTop: '6px',
                paddingRight: '6px',
                overflowX: 'scroll',
                height: '45px',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              {articleURL}
            </Grid>
            <Grid item xs={3}>
              <Button
                style={{
                  textTransform: 'none',
                  fontFamily: 'Bosch Sans',
                  backgroundColor: '#005691',
                  color: '#FFF',
                  borderRadius: 0,
                  marginLeft: '18px',
                  height: '45px'
                }}
                onClick={() => copyToClipBoard()}
              >
                {t('ui_spear.search.Share.Copy_url')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Popper>
    </div>
  );
}
